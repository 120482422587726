<template>
  <div class="normal-header">
    <div class="log">
      <img style="height: 42px; width: 42px;" :src="'favicon.svg'"
      />
      <!--
            :src="'https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/system_config_img/20231124/65606d52754ad38845.png'"
            -->
      <p>跑握AI</p>
      <span>专业的智能助理</span>
    </div>

    <div class="menu-info">
      <a-menu v-model="current" mode="horizontal">
        <template v-for="(item, index) in $router.options.routes">
          <a-menu-item :key="item.id" v-if="item.pid === 0" @click="clickTopBar">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </a-menu-item>
        </template>
      </a-menu>
    </div>

    <div class="user-info">

      <div class="icon-container">
        <MemberButton @loginSuccessCallback="loginSuccessCallback"/>
      </div>

      <div class="icon-container">
        <a-icon type="customer-service"/>
      </div>

      <div class="icon-container">
        <a-icon type="question-circle"/>
      </div>


      <a-dropdown v-if="avatar">
        <div class="icon-container">
          <a-avatar style="background:#0275d3">
            <img slot="icon" :src="avatar"/>
          </a-avatar>
          <span class="user-name">{{ name }}</span>
        </div>
        <a-menu slot="overlay">
          <a-menu-item>
            <a href="javascript:;" @click="userInfo">订单详情</a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;" @click="logout">退出登录</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>

      <div class="icon-container" @click="clickLogin" v-else>
        <a-avatar style="background:#0275d3">
          <img v-if="avatar" slot="icon" :src="avatar"/>
          <a-icon v-else slot="icon" type="user"/>
        </a-avatar>
        <span class="user-name">{{ name ? name : '暂未登录' }}</span>
      </div>


    </div>


    <LoginModal v-if="login" :modalVisible.sync="login" @loginSuccessCallback="loginSuccessCallback"/>
  </div>
</template>
<script>

export default ({
  components: {
    LoginModal: () => import("@/components/Login/LoginModal.vue"),
    MemberButton: () => import('@/components/Button/MemberButton.vue')
  },
  // computed: {
  //   name: {
  //     get() {
  //       return sessionStorage.getItem("name")
  //     }
  //   },
  //   avatar: {
  //     get() {
  //       return sessionStorage.getItem("avatar")
  //     }
  //   }
  // },
  data() {
    return {
      current: [],
      login: false,
      name: sessionStorage.getItem("name"),
      avatar: sessionStorage.getItem("avatar")
      // user: {
      //   url: this.$store.getters.avatar,
      //   name: this.$store.getters.name
      // }
    }
  },
  methods: {
    userInfo() {

    },
    loginSuccessCallback() {
      this.name = sessionStorage.getItem("name")
      this.avatar = sessionStorage.getItem("avatar")
    },
    logout() {
      this.$store.dispatch("user/logout")
      location.reload();
    },
    clickLogin() {
      this.login = true
    },
    clickTopBar(item) {
      this.setLeftBar(item.key)
    },
    // fetchNavData() {
    //   console.log("---->", this.current)
    // }

    setLeftBar(key) {
      console.log("key ====>", key)
      let arr = []
      const routes = this.$router.options.routes
      routes.forEach(item => {
        if (item.pid === key) {
          arr.push(item)
        }
      })
      console.log('arr ->', arr)
      // store.state.user.leftBar = arr
      this.$store.state.user.leftBar = arr
    }

  },
  created() {
    const routes = this.$router.options.routes
    for (let i = 0; i < routes.length; i++) {
      const item = routes[i]
      if (item.pid === 0) {
        this.current.push(item.id)
        break
      }
    }
    this.setLeftBar(this.current[0])
  },
  mounted() {
    // this.user.name = getName()
    // this.user.url = getUrl()
  }
})

</script>
